<template>
    <div class="uk-margin-medium-top uk-container uk-container-large uk-flex-auto uk-text-center" data-uk-scrollspy="target: > .animate; cls: uk-animation-slide-bottom-small uk-invisible; delay: 300">
        <h2 class="uk-heading-primary animate uk-invisible" style="font-weight: 700;"> {{"Search_catalytic" | localize}} </h2>
        <div class="uk-width-1-2@m uk-margin-auto animate uk-invisible">
            <form id="search-form"  @submit.prevent="Search">
                <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                    <input class="uk-input uk-width-1-1 search-fld" type="text" v-model='search' :placeholder="localizeFilter('Placeholder_serach')" required>
                </div>
                <div class="uk-margin-medium-top animate" data-uk-margin>
                    <button class="uk-button uk-button-primary uk-width-1-1 uk-border-rounded uk-width-auto@s"> {{'Search' | localize}} </button>
                </div>
            </form>
        </div>
        <div id="modelsSlider" class="uk-margin-auto uk-position-small uk-margin-large-top  animate uk-invisible" style="width:70%;">
            <div class="uk-position-relative  uk-visible-toggle uk-dark" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-3 uk-child-width-1-3@s uk-child-width-1-6@l">
                    <li  v-for="mark in marks" class="filtered" v-if="imageMark.includes(trimBracket(mark.text).trim().toLowerCase())">
                        <img :src="require('@/assets/marks/' + trimBracket(mark.text).trim().toLowerCase() + '.png')" alt="" height="100px" width="100px" @click="Selected(mark)">
                    </li>

                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover" style="margin-left:-10px; color:blue" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" style="margin-right:-10px; color:blue" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
            <div class="uk-margin-medium-top uk-column-1-3 uk-column-1-3@s uk-column-1-6@l">
                <p v-for="mark in marks" class="uk-link-reset" @click = "Selected(mark)"><a>{{mark.text | trimBracket}}</a></p>
            </div>
        </div>

    </div>
</template>

<script>
import {eventBus} from '@/main'
import localizeFilter from "@/filter/localize.filter"
import GetMarks from '@/config/GetMarks.general'
import trimBracket from '@/filter/trimBracket.filter'

export default {
    metaInfo:{
        title: localizeFilter('TitleSearch')
    },
    data: () => ({
        search: '',
        marks: [],
        allmarks:[],
        imageMark: ['bmw', 'audi', 'hyundai', 'kia', 'lada', 'mercedes-benz', 'nissan', 'renault', 'toyota', 'volkswagen']
    }),
    methods:{
        Search(){
            this.$router.push({name: 'search result', params: {search: this.$data.search}})
        },
        localizeFilter,
        GetMarks,
        trimBracket,
        Selected(mark){
            //this.$router.push({name: 'search result', params: {search:trimBracket(mark.text)}})
            this.$router.push({name: 'search result', params: {filter:mark}})

        }
    },
    mounted(){
        this.GetMarks()
    }
}
</script>
<style>
.filtered{
     filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    opacity: 0.4;
}

.filtered:hover{
    filter: none;
    opacity: 1;
    cursor: pointer;
}

@media screen and (max-width: 960px){
	#modelsSlider {
        width: 100% !important;
	}
}
</style>
